<template>
  <div class="invoiceCon">
    <!-- 已开票-->
    <div class="statusText">
			<div class="status_t">
				<van-icon :name="(buttonStatus == 1 || buttonStatus == 4) ? 'passed' : 'close' " size="40" :color="(buttonStatus == 1 || buttonStatus == 4) ? '' : 'red' "/>
      	<div class="text">{{InvoiceStatus}} {{InvoiceInfo.invoiceAmount || '0'}}元</div>
			</div>
			<div class="status_b">
				<p v-if="buttonStatus == 3">{{'驳回原因:' + InvoiceInfo.rejectionReason}}</p>
				<p v-else>{{buttonStatus == 2 ? '包含的订单有退款的情况，审核以后会作废原发票，满足重新开票则会重新开票' : buttonStatus == 4 ? '请耐心等待，财务正在努力处理中' : ''}}</p>
			</div>
    </div>
    <!-- 开票详情 -->
    <p class="title">开票详情</p>
    <van-cell-group>
      <van-cell title="发票类型" :value="invoiceMediumHandle()" />
      <van-cell title="发票抬头" :value="InvoiceInfo.invoiceTitle"/>
			<van-cell title="税号" :value="InvoiceInfo.enterpriseTaxNumber" />
			<van-cell title="发票内容" :value="InvoiceInfo.goodsName" />
			<van-cell title="发票金额" :value="InvoiceInfo.invoiceAmount" />
			<van-cell title="提交时间" :value="InvoiceInfo.createTime" />
    </van-cell-group>
		<!-- 接收方式 -->
		<p class="title">接收方式</p>
		<van-cell-group>
      <van-cell title="电子邮箱" :value="InvoiceInfo.mailbox" />
      <van-cell title="手机号码" :value="InvoiceInfo.mobile" />
			<van-cell title="所含订单" :value="InvoiceInfo.orderSize ? InvoiceInfo.orderSize : '0'" is-link @click="orderHandle"/>
    </van-cell-group>
		<div class="returnbox">
			<van-button class="returnPage" size="large" round @click="returnHandle">{{buttonStatus == 3 ? '重新提交' : '返回'}}</van-button>
		</div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance, computed } from 'vue';
import { useRoute } from "vue-router";
import { setStore } from "@/utils/store";
export default {
	setup(){
		const state = reactive({
			InvoiceInfo:{},
			buttonStatus:''
		})
		// 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router : router, $http : http } = ctx;
		//调用useRoute,获取页面携带的参数
    const route = useRoute();
		//发票状态
		const InvoiceStatus = computed(()=>{
			switch (Number(route.query.status)) {
					case 1:
						state.buttonStatus = 1
						return "已开票";
					case 2:
						state.buttonStatus = 2
						return "待红冲";
					case 3:
						state.buttonStatus = 3
						return "已驳回";
					case 4:
						state.buttonStatus = 4
						return "待审核";
					case 5:
						return "已红冲";
					case 6:
						return "开票失败";
					case 7:
						return "已取消";
					case 10:
						return "未知";
					default:
						return "未知";
      	}
		})
		const methods = {
			//重新提交、返回
			returnHandle(){
				if(Number(route.query.status) == 3){
					// 保存发票驳回数据
					setStore({
						name: "rejectInvoiceData",
						content: state.InvoiceInfo,
						type: "session"
					});
					router.push({
						name : `apply-invoiced`,
						query:{
							totalOrder : state.InvoiceInfo.orderSize || 0,
							count : state.InvoiceInfo.invoiceAmount || 0,
							type : 0,
							status : 3,
						}
					})
					return
				}
				window.history.go(-1)
			},
			// 所含订单
			orderHandle(){
				if(Boolean(state.InvoiceInfo.orderSize)){
					router.push({
						name:'invoiced-order',
						query:{
							id : state.InvoiceInfo.id
						}
					})
				}
			},
			// 发票介质 目前只有电子发票
			invoiceMediumHandle() {
				switch (state.InvoiceInfo.invoiceMedium) {
					case 0:
						return "电子发票";
					default:
						return "未知";
				}
			},
			// 获取发票详情
			getInvoiceDetail(){
				http.get(`/invoice/info/${route.query.id}`,{ params: {}}).then(({data : res})=>{
					if(res.code == 0){
						Object.assign(state.InvoiceInfo,res.zyInvoice,{ goodsName : res.goodsName })
						return 
					}
					tool.toast({
            msg: res.msg,
            duration: 1500
          });
				})
			}
		}
		onMounted(()=>{
			ctx.getInvoiceDetail()
		})
		return {
			...toRefs(state),
			...methods,
			InvoiceStatus
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.invoiceCon {
  height: 100vh;
  margin: 0 auto;
  background: #FFFFFF;
  .statusText {
    display: flex;
		flex-direction: column;
    padding-left: 20px;
    padding-top: 10px;
		.status_t{
			display: flex;
			align-items: center;
			padding-left: 20px;
			padding-top: 10px;
		}
    .text {
      padding-left: 6px;
      font-weight: bold;
    }
  }
	.title{
		margin: 30px 0px;
	}
	.returnbox{
		width: 690px;
		margin: 30px auto;
		.returnPage{
			color: #fff;
			width: 100%;
			@include background_color("background_color1");
		}
	}
}
.van-cell{
	padding: 16px 16px;
}
</style>
